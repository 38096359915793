// src/components/TagManagerComponent.js
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const TagManagerComponent = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID, // Carga el ID desde la variable de entorno
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return null; // Este componente no renderiza nada
};

export default TagManagerComponent;
