import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LineaContact.css';

const LineaContact = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  // Abre el modal al hacer click en el botón principal
  const openModal = () => {
    setShowModal(true);
  };

  // Cierra el modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Maneja la selección de país, envía el evento a GA y navega a la ruta correspondiente
  const handleSelection = (country) => {
    // Evento de Google Analytics (Data Layer Push)
    if (window && window.gtag) {
      window.gtag('event', 'iniciar_contacto', { 
        event_category: 'engagement',
        event_label: `Iniciar Cotización - ${country}`,
        value: 1
      });
    }

    if (country === 'colombia') {
      navigate('/colombia');
    } else if (country === 'peru') {
      navigate('/peru');
    } else if (country === 'otros') {
      navigate('/contacto');
    }
    closeModal();
  };

  return (
    <>
      <button className="linea-contact-button" onClick={openModal}>
        SOLICITA COTIZACIÓN
      </button>
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2 className="modal-title">Elige tu país</h2>
            <button
              className="modal-button"
              onClick={() => handleSelection('colombia')}
            >
              Colombia
            </button>
            <button
              className="modal-button"
              onClick={() => handleSelection('peru')}
            >
              Perú
            </button>
            <button
              className="modal-button"
              onClick={() => handleSelection('otros')}
            >
              Otros países
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LineaContact;
