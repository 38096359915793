import React, { useState } from "react";
import "./LeadsExplorer.css";

const LeadsExplorer = ({ leads, formatDate, updateLeadStatus }) => {
  // Estado para el filtro por status: inicia en "nuevo"
  const [filter, setFilter] = useState("nuevo");
  // Estado para el orden de la fecha ("desc" para más reciente primero)
  const [sortOrder, setSortOrder] = useState("desc");
  // Estado para la visualización del modal (lead seleccionado)
  const [selectedLead, setSelectedLead] = useState(null);

  // Filtrado de leads según el estado seleccionado
  const filteredLeads = leads.filter(
    (lead) => filter === "todos" || lead.status === filter
  );

  // Ordenar los leads filtrados por fecha de creación
  const sortedLeads = filteredLeads.sort((a, b) => {
    return sortOrder === "asc"
      ? new Date(a.created_at) - new Date(b.created_at)
      : new Date(b.created_at) - new Date(a.created_at);
  });

  // Alterna el orden de la fecha al hacer clic en el encabezado
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Asigna las clases CSS según el estado
  const getStatusClass = (status) => {
    switch (status) {
      case "nuevo":
        return "lead-explorer-status-new";
      case "en proceso":
        return "lead-explorer-status-in-progress";
      case "atendido":
        return "lead-explorer-status-attended";
      default:
        return "";
    }
  };

  // Cierra el modal
  const closeModal = () => {
    setSelectedLead(null);
  };

  return (
    <>
      <div className="lead-explorer-filter-container">
        <label htmlFor="status-filter">Filtrar por estado:</label>
        <select
          id="status-filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="todos">Todos</option>
          <option value="nuevo">Nuevo</option>
          <option value="en proceso">En Proceso</option>
          <option value="atendido">Atendido</option>
        </select>
      </div>

      <table className="lead-explorer-table">
        <thead>
          <tr>
            <th className="lead-explorer-table-header">Nombre</th>
            <th className="lead-explorer-table-header">Teléfono</th>
            <th className="lead-explorer-table-header">Correo</th>
            <th className="lead-explorer-table-header">Estado</th>
            <th
              className="lead-explorer-table-header sort-header"
              onClick={toggleSortOrder}
            >
              Creado el {sortOrder === "asc" ? "▲" : "▼"}
            </th>
            <th className="lead-explorer-table-header">Ver más</th>
          </tr>
        </thead>
        <tbody>
          {sortedLeads.map((lead) => (
            <tr key={lead.id}>
              <td className="lead-explorer-table-cell">{lead.name}</td>
              <td className="lead-explorer-table-cell">{lead.phone}</td>
              <td className="lead-explorer-table-cell">{lead.email}</td>
              <td
                className={`lead-explorer-table-cell ${getStatusClass(
                  lead.status
                )}`}
              >
                <select
                  value={lead.status}
                  onChange={(e) =>
                    updateLeadStatus(lead.id, e.target.value)
                  }
                  className="lead-explorer-status-dropdown"
                >
                  <option value="nuevo">Nuevo</option>
                  <option value="en proceso">En Proceso</option>
                  <option value="atendido">Atendido</option>
                </select>
              </td>
              <td className="lead-explorer-table-cell">
                {formatDate(lead.created_at)}
              </td>
              <td className="lead-explorer-table-cell">
                <button
                  onClick={() => setSelectedLead(lead)}
                  className="lead-explorer-button-view-more"
                >
                  Ver más
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedLead && (
        <div className="lead-explorer-modal" onClick={closeModal}>
          <div
            className="lead-explorer-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{selectedLead.name}</h2>
            <p>
              <strong>Teléfono:</strong> {selectedLead.phone}
            </p>
            <p>
              <strong>Correo:</strong> {selectedLead.email}
            </p>
            <p>
              <strong>Estado:</strong> {selectedLead.status}
            </p>
            <p>
              <strong>Creado el:</strong> {formatDate(selectedLead.created_at)}
            </p>
            <button onClick={closeModal} className="lead-explorer-button">
              Cerrar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadsExplorer;
