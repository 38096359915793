// /src/components/Linea/SeriesShowcase.js
import React, { useState, useEffect, useRef } from "react";
import "./SeriesShowcase.css";
import SeriesCards from "./SeriesCards";
import nextArrow from '../../images/lineaslider/next-desktop.webp';
import prevArrow from '../../images/lineaslider/prev-desktop.webp';

function SeriesShowcase({ series, selectedSerie, setSelectedSerie, linea }) {
  // Hook para calcular la cantidad de slides visibles según el ancho de pantalla
  const [slidesToShow, setSlidesToShow] = useState(2);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width >= 1280) {
        setSlidesToShow(4);
      } else if (width >= 1024) {
        setSlidesToShow(3);
      } else if (width >= 768) {
        setSlidesToShow(2);
      } else if (width >= 640) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(1);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Clonar slides para lograr el efecto infinito
  const clonedStart = series.slice(-slidesToShow);
  const clonedEnd = series.slice(0, slidesToShow);
  const sliderSeries = [...clonedStart, ...series, ...clonedEnd];

  // Índice actual (se inicia en slidesToShow para estar en el primer elemento original)
  const [currentIndex, setCurrentIndex] = useState(slidesToShow);
  // Bandera para controlar la transición (se desactiva momentáneamente para el reajuste)
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const sliderRef = useRef(null);

  // Función para avanzar al siguiente slide
  const handleNext = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  // Función para retroceder al slide anterior
  const handlePrev = () => {
    setCurrentIndex((prev) => prev - 1);
  };

  // Al finalizar la transición, si estamos en clones se reajusta el índice sin animación
  const handleTransitionEnd = () => {
    if (currentIndex >= series.length + slidesToShow) {
      setTransitionEnabled(false);
      setCurrentIndex(slidesToShow);
    } else if (currentIndex < slidesToShow) {
      setTransitionEnabled(false);
      setCurrentIndex(series.length + slidesToShow - 1);
    }
  };

  useEffect(() => {
    if (!transitionEnabled) {
      // Forzar un reflow y reactivar la transición en el siguiente ciclo
      setTimeout(() => {
        setTransitionEnabled(true);
      }, 0);
    }
  }, [transitionEnabled]);

  // Cálculo del ancho de cada slide y el translateX del slider
  const slideWidth = 100 / slidesToShow; // en porcentaje
  const translateX = -currentIndex * slideWidth;

  return (
    <div className="series-showcase-container">
      <button className="series-showcase-button series-showcase-prev" onClick={handlePrev}>
        <img src={prevArrow} alt="Prev" className="series-showcase-arrow-image" />
      </button>
      <div className="series-showcase-wrapper">
        <div
          className="series-showcase-slider"
          ref={sliderRef}
          style={{
            transform: `translateX(${translateX}%)`,
            transition: transitionEnabled ? "transform 0.5s ease" : "none",
          }}
          onTransitionEnd={handleTransitionEnd}
        >
          {sliderSeries.map((serie, index) => (
            <div
              className="series-showcase-slide"
              key={index}
              style={{ flex: `0 0 ${slideWidth}%` }}
            >
              <SeriesCards
                serie={serie}
                isSelected={selectedSerie?.documentId === serie.documentId}
                linea={linea}
              />
            </div>
          ))}
        </div>
      </div>
      <button className="series-showcase-button series-showcase-next" onClick={handleNext}>
        <img src={nextArrow} alt="Next" className="series-showcase-arrow-image" />
      </button>
    </div>
  );
}

export default SeriesShowcase;