import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LineaButton.css';

const LineaButton = ({ linea }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!linea || !linea.documentId) {
      console.error("No se encontró documentId en la línea. Valor actual de 'linea':", linea);
      return;
    }
    const stateToPass = {
      selectedLineaId: linea.documentId,
      selectedSerie: null
    };
    console.log("Navegando a /productos con:", stateToPass);
    navigate('/productos', { state: stateToPass });
  };

  return (
    <button className="linea-button" onClick={handleClick}>
      VER PRODUCTOS
    </button>
  );
};

export default LineaButton;
