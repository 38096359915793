// src/components/GTMPageView.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const GTMPageView = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
      },
    });
  }, [location]);

  return null;
};

export default GTMPageView;
