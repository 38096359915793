import React from 'react';
import './HomeQuotaButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const HomeQuotaButton = () => {
  return (
    <Link to="/contacto" className="home-quota-button-container">
      <div className="home-quota-button-content">
        <span>COTIZAR</span>
      </div>
      <div className="home-quota-button-arrow">
        <FontAwesomeIcon icon={faAngleRight} className="home-quota-button-arrow-icon" />
      </div>
    </Link>
  );
};

export default HomeQuotaButton;
