import React, { useEffect, useState } from 'react';

const ProductDataProvider = ({ children, initialSelectedLinea = null, initialSelectedSerie = null }) => {
  const [lineas, setLineas] = useState([]);
  const [selectedSerie, setSelectedSerie] = useState(initialSelectedSerie);
  const [selectedLinea, setSelectedLinea] = useState(initialSelectedLinea);
  const [modelos, setModelos] = useState([]);
  const [loadingLineas, setLoadingLineas] = useState(true);
  const [loadingModelos, setLoadingModelos] = useState(false);
  const [error, setError] = useState(null);

  // Sincronizar con selecciones iniciales
  useEffect(() => {
    setSelectedLinea(initialSelectedLinea);
    setSelectedSerie(initialSelectedSerie);
  }, [initialSelectedLinea, initialSelectedSerie]);

  // Cargar líneas con sus series
  useEffect(() => {
    const fetchLineas = async () => {
      setLoadingLineas(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lineas?populate=series`
        );
        const result = await response.json();
        
        if (!result.data) throw new Error('Error al cargar líneas');
        setLineas(result.data);
        
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingLineas(false);
      }
    };
    fetchLineas();
  }, []);

  // Cargar modelos según selección
// Cargar modelos según selección
useEffect(() => {
  const fetchModelos = async () => {
    setLoadingModelos(true);
    setModelos([]);

    try {
      let url = `${process.env.REACT_APP_API_URL}/modelos?`;
      let shouldFetch = true;

      // Actualizamos la condición para usar documentId en lugar de id
      if (selectedSerie?.documentId) {
        url += `filters[serie][documentId][$eq]=${selectedSerie.documentId}`;
      } else if (selectedLinea) {
        const linea = lineas.find(l => l.documentId === selectedLinea);
        const seriesIds = linea?.series?.map(s => s.documentId) || [];
        
        if (seriesIds.length === 0) {
          shouldFetch = false;
          setError('La línea no tiene series asociadas');
        } else {
          url += seriesIds
            .map((id, i) => `filters[serie][documentId][$in][${i}]=${id}`)
            .join('&');
        }
      } else {
        shouldFetch = false;
      }

      if (shouldFetch) {
        const response = await fetch(url);
        const result = await response.json();
        
        const parsedModelos = (result.data || []).map(modelo => ({
          documentId: modelo.documentId,
          nombre: modelo.nombre || 'Sin nombre',
          descripcion: modelo.descripcion,
          slug: modelo.slug,
          especificaciones: modelo.especificaciones || [],
          imagen_card: modelo.imagen_card?.url || null,
        }));

        setModelos(parsedModelos);
        setError(null);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingModelos(false);
    }
  };

  fetchModelos();
}, [selectedSerie, selectedLinea, lineas]);


  return children({
    lineas,
    selectedSerie,
    setSelectedSerie,
    selectedLinea, 
    setSelectedLinea,
    modelos,
    loadingLineas,
    loadingModelos,
    error,
  });
};

export default ProductDataProvider;
