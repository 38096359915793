import React, { useEffect } from 'react';
import "./Linea.css";
import { useLocation } from 'react-router-dom';
import PageFooter from '../components/PageFooter';
import LineaBanner from "../components/Linea/LineaBanner";
import LineaWrapper from "../components/Linea/LineaWrapper";


function Linea() {

  const location = useLocation();

  // Obtener selecciones desde el estado de navegación
  const navState = location.state || {};
  const initialSelectedLinea = navState.selectedLineaId;

  useEffect(() => {
    document.title = 'Zoomlion Latinoamérica | Líneas';
    const metaDescription = document.querySelector('meta[name="description"]') ||
      document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Catálogo completo de maquinaria Zoomlion';
    document.head.appendChild(metaDescription);
  }, []);

  return (
    <div className="linea-page">
      <LineaBanner />
      <LineaWrapper />
      <PageFooter />
    </div>
  );
}

export default Linea;
