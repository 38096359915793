import React from 'react';
import CustomCard from './CustomCard';
import './CustomShowcase.css';

function CustomShowcase({ cardsData, cardsToShow = 3 }) {
  return (
    <div
      className="custom-showcase-container"
      style={{ "--cards-desktop": cardsToShow }}
    >
      {cardsData.slice(0, cardsToShow).map((data, index) => (
        <CustomCard key={index} data={data} />
      ))}
    </div>
  );
}

export default CustomShowcase;
