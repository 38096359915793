import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PageFooter from '../components/PageFooter';
import LandingFeature from '../components/Landing/LandingFeature/LandingFeature';
import HeroCanvas from '../components/Landing/HeroCanvas';
import './Landing.css';
import '../components/Landing/LandingForm.css';
import WhatsappComponent from '../components/Landing/WhatsappComponent';
import LandingDataWrapper from '../components/Landing/LandingDataWrapper';
import { submitLandingForm } from '../api/LandingApi';
import featureContent from '../components/Landing/LandingFeature/featureContent.json'; // Importamos el JSON con la información

const Landing = () => {
  const location = useLocation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  // Determinar el país basado en la ruta
  const isColombia = location.pathname.includes('colombia');
  const currentCountry = isColombia ? 'Colombia' : 'Perú';

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    city: '',
    company: '',
    ruc: '',
    machines: '',
    pais: currentCountry,
  });

  const formRef = useRef(null);

  // Estado para el modal
  const [modal, setModal] = useState({ visible: false, message: '', type: '' });

  const closeModal = () => {
    setModal({ visible: false, message: '', type: '' });
  };

  // Configuración dinámica por país
  const countryConfig = {
    Perú: {
      title: 'Zoomlion Latinoamérica | Perú',
      description: 'Conoce las ofertas de Zoomlion Perú',
      whatsappComponent: <WhatsappComponent country="Perú" />,
      catalogueLink: "https://heyzine.com/flip-book/f34195a0ca.html#page/1",
      privacyPolicy: "https://zoomlionlatam.com/privacidad/privacidad_peru.pdf",
    },
    Colombia: {
      title: 'Zoomlion Latinoamérica | Colombia',
      description: 'Conoce las ofertas de Zoomlion Colombia',
      whatsappComponent: <WhatsappComponent country="Colombia" />,
      catalogueLink: "https://heyzine.com/flip-book/f34195a0ca.html#page/1",
      privacyPolicy: "https://zoomlionlatam.com/privacidad/privacidad_colombia.pdf",
    }
  };

  // Imágenes comunes para ambos países
  const imageList = [
    '/images/Landing/grua.png',
    '/images/Landing/agricultura.png',
    '/images/Landing/mineria.png',
    '/images/Landing/construccion.png',
  ];

  // Configuración actual basada en el país
  const config = countryConfig[currentCountry];

  useEffect(() => {
    document.title = config.title;
    const metaDescription = document.querySelector('meta[name="description"]') || 
      document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = config.description;
    document.head.appendChild(metaDescription);
  }, [currentCountry, config]);

  // Carrusel de imágenes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [imageList.length]);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseMessage = await submitLandingForm(formData);
      
      // Evento de Google Analytics al enviar el formulario
      if (window && window.gtag) {
        window.gtag('event', 'lead_enviado', {
          event_category: 'engagement',
          event_label: `Solicitud de Cotización - ${currentCountry}`,
          value: 1
        });
      }
      
      // Muestra modal de éxito
      setModal({ visible: true, message: "¡Mensaje enviado exitosamente!", type: "success" });
    } catch (error) {
      // Muestra modal de error
      setModal({ visible: true, message: "Hubo un error al enviar el formulario. Por favor, inténtalo nuevamente.", type: "error" });
    }
  };

  return (
    <div className="landing-peru-page">
      {config.whatsappComponent}

      {/* Hero Section */}
      <div className="landing-peru-hero">
        <HeroCanvas />
        <div className="landing-peru-text-section">
          <h1 className="landing-peru-title">
            <span>Maquinaria</span> <span>pesada</span> <span>de</span> <span>alta</span> <span>calidad</span> <span>para</span> <span>minería,</span> <span>construcción</span> <span>y</span> <span>agro</span> <span>en</span> 
            <span> {currentCountry}</span>
          </h1>
          <p className="landing-peru-subtitle">
            Nueve líneas de equipos combinando innovación, tecnología inteligente y soluciones sostenibles.
          </p>
          <div className="landing-peru-image-section">
            <img
              key={currentImageIndex}
              src={imageList[currentImageIndex]}
              alt="Service"
              className="landing-peru-page-img slide-image"
            />
          </div>
          
          <div className="landing-peru-button-wrapper">
            <button className="landing-peru-contact-button" onClick={scrollToForm}>
              COTIZAR
            </button>
            <button
              className="landing-peru-catalogue-button"
              onClick={() => window.open(config.catalogueLink, "_blank")}
            >
              VER CATÁLOGO
            </button>
          </div>
        </div>
      </div>

      {/* Sección de LandingFeatures renderizando cada uno según el JSON */}
      <div className="landing-features-wrapper">
      {Object.keys(featureContent).map((key, index) => (
  <LandingFeature key={key} type={key} index={index} />
))}

      </div>

      <LandingDataWrapper country={currentCountry}/>

      <div ref={formRef} className="landing-peru-form-section">
        <h2 className="landing-peru-form-title">Pongámonos en contacto</h2>
        <div className="landing-peru-form-content">
          <form className="landing-peru-form" onSubmit={handleSubmit}>
            <div className="landing-peru-form-group">
              <label htmlFor="name">Nombres y apellidos:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Tus nombres y apellidos"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="phone">Celular:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Tu celular"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="email">Correo:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Tu correo"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="city">Ciudad:</label>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="Tu ciudad"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="company">Empresa:</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Tu empresa"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="ruc">RUC:</label>
              <input
                type="text"
                id="ruc"
                name="ruc"
                placeholder="Tu RUC"
                value={formData.ruc}
                onChange={handleChange}
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="machines">Máquina(s) de interés:</label>
              <textarea
                id="machines"
                name="machines"
                placeholder="Escribe aquí..."
                value={formData.machines}
                onChange={handleChange}
              ></textarea>
            </div>
            <input type="hidden" name="pais" value={currentCountry} />
            
            <button type="submit" className="landing-peru-form-submit-button">Enviar</button>

            <a
              href={config.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
              className="landing-peru-form-privacy"
            >
              Política de privacidad
            </a>
          </form>
        </div>
      </div>

      {/* Modal */}
      {modal.visible && (
        <div className="landing-peru-modal">
          <div className="landing-peru-modal-content">
            <p>{modal.message}</p>
            <button className="landing-peru-modal-close-button" onClick={closeModal}>Cerrar</button>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="landing-peru-page-footer">
        <PageFooter country={currentCountry} />
      </div>
    </div>
  );
};

export default Landing;
