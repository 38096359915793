import React, { useEffect } from 'react';


function DebugDataAlert({ data }) {
  useEffect(() => {
    /*
   console.log("Debug Data:", data);
   /* 
    alert(JSON.stringify(data, null, 2));
*/  
}, [data]);

  return null;
}

export default DebugDataAlert;
