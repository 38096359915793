import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeLineaSliderDesktop.css';
import nextArrow from '../../../../images/lineaslider/next-desktop.webp';
import prevArrow from '../../../../images/lineaslider/prev-desktop.webp';

const DEBUG = true; // Cambia a false para desactivar los mensajes de debug

function NextArrow(props) {
  const { className, style, onClick } = props;
  /*if (DEBUG) console.log("NextArrow props:", { className, style });*/
  return (
    <div
      className={`${className} home-linea-slider-desktop-arrow home-linea-slider-desktop-next`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next" className="home-linea-slider-desktop-arrow-image" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
 /* if (DEBUG) console.log("PrevArrow props:", { className, style });*/
  return (
    <div
      className={`${className} home-linea-slider-desktop-arrow home-linea-slider-desktop-prev`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Previous" className="home-linea-slider-desktop-arrow-image" />
    </div>
  );
}

const HomeLineaSliderDesktop = ({ lineas = [], onLineaChange, sliderRef, loading }) => {
  const [selectedLinea, setSelectedLinea] = useState(0);
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleLineaClick = (index) => {
    setSelectedLinea(index);
    onLineaChange(index);
    setClickedIndex(index);
    setTimeout(() => setClickedIndex(null), 300);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 6,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="home-linea-slider-desktop-wrapper">
      <div className="home-linea-slider-desktop">
        {!loading && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {lineas.map((linea, index) => {
              // Debug: Mostramos la estructura de cada línea
              if (DEBUG) {
                if (!linea.documentId) {
              /*    console.error(`Error: La línea en índice ${index} no tiene documentId. Datos:`, linea);
                /*  alert(`Error: La línea en índice ${index} no tiene documentId.`);*/
                }
              }
              return (
                <div key={linea.documentId ? linea.documentId : `no-id-${index}`} onClick={() => handleLineaClick(index)}>
                  <button
                    className={`home-linea-slider-desktop-slide-button ${clickedIndex === index ? 'clicked' : ''}`}
                  >
                    <div
                      className={`home-linea-slider-desktop-item ${selectedLinea === index ? 'selected' : ''}`}
                    >
                      <div className="home-linea-slider-desktop-image-wrapper">
                        <img
                          src={linea.image || "/fallback-image.png"}
                          alt={linea.nombre || "Sin nombre"}
                          className="home-linea-slider-desktop-image"
                        />
                      </div>
                      <h3 className="home-linea-slider-desktop-label">{linea.nombre || "Sin nombre"}</h3>
                    </div>
                  </button>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default HomeLineaSliderDesktop;
