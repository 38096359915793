import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './PageNavbarDesktop.css';
import logo from '../../../images/logo.webp';
import SearchIcon from '@mui/icons-material/Search';
import CloseSearchIcon from '@mui/icons-material/HighlightOff';
import SearchButtonIcon from '@mui/icons-material/Search';
import Submenu from './Submenu';

const PageNavbarDesktop = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const submenuTimeoutRef = useRef(null);
  // Eliminamos currentSection ya que no se usará más
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(false);
  const location = useLocation(); 
  const [isHovering, setIsHovering] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const searchResultsRef = useRef(null);
  const searchBarRef = useRef(null);
  const navigate = useNavigate();

  // Estado para el modal de Campaña
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);

  // Al cambiar de ruta, se cierra la búsqueda
  useEffect(() => {
    setSearchOpen(false);
  }, [location]);

  // Inicia transparente en la homepage
  useEffect(() => {
    if (location.pathname === '/') {
      setIsNavbarTransparent(true);
    } else {
      setIsNavbarTransparent(false);
    }
  }, [location.pathname]);

  // Listener para detectar el primer scroll y fijar el navbar como no transparente
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && !hasScrolled) {
        setHasScrolled(true);
        setIsNavbarTransparent(false);
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolled]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = menuOpen ? 'auto' : 'hidden';
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      setNoResults(false);
      return;
    }

    setIsSearching(true);

    try {
      // Llama al endpoint del backend para realizar la búsqueda.
      const response = await fetch(`${process.env.REACT_APP_MEILI_API_URL}?q=${encodeURIComponent(searchQuery)}`);
      const data = await response.json();

      if (data.hits && data.hits.length > 0) {
        setSearchResults(data.hits);
        setNoResults(false);
      } else {
        setSearchResults([]);
        setNoResults(true);
      }
      setSearchOpen(true);
    } catch (error) {
      console.error('Error durante la búsqueda:', error);
      setNoResults(true);
    } finally {
      setIsSearching(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
    if (!searchOpen) {
      setSearchResults([]);
      setNoResults(false);
      setSearchQuery('');
    }
  };

  const handleViewMore = () => {
    navigate('/productos');
    toggleSearch();
  };

  // Manejar apertura/cierre del submenú
  const handleMouseEnter = () => {
    if (submenuTimeoutRef.current) {
      clearTimeout(submenuTimeoutRef.current);
    }
    setSubmenuOpen(true);
  };

  const handleMouseLeave = () => {
    submenuTimeoutRef.current = setTimeout(() => {
      setSubmenuOpen(false);
    }, 200);
  };

  // Manejadores de hover: si se hace hover se fuerza el navbar a no transparente,
  // pero al salir, si aún no se ha hecho scroll se vuelve a la transparencia inicial
  const handleNavbarMouseEnter = () => {
    setIsHovering(true);
    setIsNavbarTransparent(false);
  };

  const handleNavbarMouseLeave = () => {
    setIsHovering(false);
    if (!hasScrolled && location.pathname === '/') {
      setIsNavbarTransparent(true);
    } else {
      setIsNavbarTransparent(false);
    }
  };

  // Detectar clic fuera del área de búsqueda y del modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
        setSearchQuery('');
      }
      // Cierra el modal de campaña si se hace click fuera
      if (campaignModalOpen) {
        const modalContent = document.querySelector('.modal-content');
        if (modalContent && !modalContent.contains(event.target)) {
          setCampaignModalOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [campaignModalOpen]);

  // Manejador para la selección de país en el modal de campaña
  const handleCampaignSelection = (country) => {
    if (country === 'colombia') {
      navigate('/colombia');
    } else if (country === 'peru') {
      navigate('/peru');
    }
    setCampaignModalOpen(false);
  };

  return (
    <>
      <nav className={`page-navbar ${isNavbarTransparent ? 'page-navbar-transparent' : ''}`}>
        <div className="page-navbar-content">
          <div className="page-navbar-logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>

          <div className="page-navbar-links-desktop">
            <div
              onMouseEnter={handleNavbarMouseEnter}
              onMouseLeave={handleNavbarMouseLeave}
            >
              <Link to="/">INICIO</Link>
            </div>

            <div
              className="navbar-productos-container"
              onMouseEnter={(e) => {
                handleMouseEnter();
                handleNavbarMouseEnter();
              }}
              onMouseLeave={(e) => {
                handleMouseLeave();
                handleNavbarMouseLeave();
              }}
            >
              <Link to="/productos" className="navbar-productos">
                PRODUCTOS
              </Link>
              {submenuOpen && <Submenu onClose={() => setSubmenuOpen(false)} />}
            </div>

            <div
              onMouseEnter={handleNavbarMouseEnter}
              onMouseLeave={handleNavbarMouseLeave}
            >
              <Link to="/servicios">SERVICIOS</Link>
            </div>

            <div
              onMouseEnter={handleNavbarMouseEnter}
              onMouseLeave={handleNavbarMouseLeave}
            >
              <Link to="/noticias">NOTICIAS</Link>
            </div>
            <div
              onMouseEnter={handleNavbarMouseEnter}
              onMouseLeave={handleNavbarMouseLeave}
            >
              <Link to="/contacto">CONTACTO</Link>
            </div>
            {/* Nuevo enlace para Campañas */}
            <div
              className="navbar-desktop-campana"
              onMouseEnter={handleNavbarMouseEnter}
              onMouseLeave={handleNavbarMouseLeave}
              onClick={() => setCampaignModalOpen(true)}
            >
              CAMPAÑAS
            </div>
          </div>
        </div>
      </nav>

      {/* Modal de Campaña */}
      {campaignModalOpen && (
        <div className="modal-overlay" onClick={() => setCampaignModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2 className="modal-title">Elige tu país</h2>
            <button className="modal-button" onClick={() => handleCampaignSelection('colombia')}>
              Colombia
            </button>
            <button className="modal-button" onClick={() => handleCampaignSelection('peru')}>
              Perú
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PageNavbarDesktop;
