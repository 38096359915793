import { useEffect, useState, useRef, useCallback, useMemo } from 'react';

const useHomeShowcaseData = () => {
  // Estados de datos
  const [lineas, setLineas] = useState([]);
  const [currentLinea, setCurrentLinea] = useState("");
  const [loading, setLoading] = useState(true);
  
  // Refs para sliders (si es necesario)
  const lineaSliderRef = useRef(null);
  const serieSliderRef = useRef(null);

  // Función para obtener y procesar la data (trabajando solo con documentId)
  const fetchLineas = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/lineas?populate[icono_landing]=true&populate[series][populate]=imagen_landing`
      );
      const data = await response.json();
      if (data && data.data) {
        const fetchedLineas = data.data.map(item => {
          const iconoLanding = item.icono_landing?.url || "";
          const series = (item.series || []).map(serie => {
            const imagenLanding = serie.imagen_landing?.[0]?.url || "";
            return {
              documentId: serie.documentId, // Trabajamos solo con documentId
              nombre: serie.nombre || "Sin nombre",
              image: imagenLanding,
              hidden: serie.hidden || false,
              posicion: serie.posicion || 0,
            };
          });
          return {
            documentId: item.documentId, // Trabajamos solo con documentId
            nombre: item.nombre || "Sin nombre",
            image: iconoLanding,
            series: series.sort((a, b) => a.posicion - b.posicion),
          };
        });
        setLineas(fetchedLineas);
      } else {
        console.error("La data de lineas no tiene la estructura esperada:", data);
      }
    } catch (error) {
      console.error("Error al obtener las lineas:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Ejecuta el fetch al montar
  useEffect(() => {
    fetchLineas();
  }, [fetchLineas]);

  // Una vez que se tienen las líneas, asigna la primera como currentLinea
  useEffect(() => {
    if (lineas.length > 0) {
      setCurrentLinea(lineas[0].documentId);
/*console.log("DocumentIds de lineas:", lineas.map(l => l.documentId));*/

    }
  }, [lineas]);

  // Función para cambiar la línea actual (por índice)
  const handleLineaChange = useCallback((index) => {
    const selectedLinea = lineas[index];
    if (selectedLinea) {
      setCurrentLinea(selectedLinea.documentId);
      if (serieSliderRef.current) {
        serieSliderRef.current.slickGoTo(0);
      }
    }
  }, [lineas]);

  // Calcula las series de la línea actual (usando documentId)
  const currentLineaSeries = useMemo(() => {
    const lineaObj = lineas.find(linea => linea.documentId === currentLinea);
    return lineaObj ? lineaObj.series : [];
  }, [lineas, currentLinea]);

  return {
    lineas,
    currentLinea,
    currentLineaSeries,
    loading,
    handleLineaChange,
    lineaSliderRef,
    serieSliderRef,
  };
};

export default useHomeShowcaseData;
