import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Submenu.css';

const Submenu = ({ onClose }) => {
  const [lineas, setLineas] = useState([]);
  const [activeLinea, setActiveLinea] = useState(null);
  const [activeSerie, setActiveSerie] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Cargar datos optimizado: se usa exclusivamente documentId
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lineas?populate=series.modelos`
        );
        const { data } = await response.json();

        const processedData = data.map(linea => ({
          documentId: linea.documentId,
          nombre: linea.nombre,
          image: linea.navegacion?.url || '',
          posicion: linea.posicion || 0,
          series: (linea.series || []).map(serie => ({
            documentId: serie.documentId,
            nombre: serie.nombre,
            image: serie.imagen_landing?.[0]?.url || '',
            modelos: (serie.modelos || []).map(modelo => ({
              documentId: modelo.documentId,
              nombre: modelo.nombre,
              slug: modelo.slug
            }))
          }))
        }));

        setLineas(processedData.sort((a, b) => a.posicion - b.posicion));
        setLoading(false);
      } catch (error) {
        console.error('Error cargando datos:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Navegación unificada: se pasan los datos usando documentId
  const navigateToProductos = (lineaId = null, serie = null) => {
    const stateToPass = {
      selectedLineaId: lineaId,
      selectedSerie: serie
    };
    /*console.log("Navegando a /productos con:", stateToPass);*/
    navigate('/productos', { state: stateToPass });
    onClose();
  };

  // Al hacer click en una línea
  const handleLineaClick = (linea) => {
    /*console.log("handleLineaClick - línea seleccionada:", linea);*/
    setActiveLinea(linea);
    navigateToProductos(linea.documentId);
  };

  // Al hacer click en una serie, ahora usamos activeLinea (si está definida)
  const handleSerieClick = (serie) => {
    /*console.log("handleSerieClick - serie seleccionada:", serie);*/
    // Usamos activeLinea para obtener el documentId
    const lineaId = activeLinea ? activeLinea.documentId : null;
    if (!lineaId) {
      console.error("No hay línea activa al seleccionar la serie.");
      alert("Error: No hay línea activa.");
      return;
    }
    navigateToProductos(lineaId, {
      documentId: serie.documentId,
      nombre: serie.nombre
    });
    setActiveSerie(serie);
  };

  const handleSeeMoreClick = () => {
    if (activeLinea && activeSerie) {
      /*console.log("handleSeeMoreClick - Línea y Serie activas:", activeLinea, activeSerie);*/
      navigateToProductos(activeLinea.documentId, {
        documentId: activeSerie.documentId,
        nombre: activeSerie.nombre
      });
    }
  };

  const handleModelClick = (modelSlug) => {
    window.gtag('event', 'select_model', {
      model_slug: modelSlug,
      event_category: 'navigation'
    });
    navigate(`/modelo/${modelSlug}`);
    onClose();
  };

  return (
    <div className="navbar-submenu">
      {loading ? (
        <div>Cargando productos...</div>
      ) : (
        <>
          <div className="navbar-submenu-column-1">
            {lineas.map(linea => (
              <div
                key={linea.documentId}
                className={`navbar-submenu-linea ${activeLinea?.documentId === linea.documentId ? 'active' : ''}`}
                onMouseEnter={() => setActiveLinea(linea)}
                onClick={() => handleLineaClick(linea)}
              >
                <img src={linea.image} alt={linea.nombre} className="navbar-submenu-icon" />
                <span>{linea.nombre}</span>
              </div>
            ))}
          </div>

          <div className="navbar-submenu-column-2">
            {activeLinea?.series?.map(serie => (
              <div
                key={serie.documentId}
                className={`navbar-submenu-serie ${activeSerie?.documentId === serie.documentId ? 'active' : ''}`}
                onMouseEnter={() => setActiveSerie(serie)}
                onClick={() => handleSerieClick(serie)}
              >
                <span>{serie.nombre}</span>
              </div>
            ))}
          </div>

          <div className="navbar-submenu-column-3">
            {activeSerie?.modelos?.slice(0, 8).map(modelo => (
              <div
                key={modelo.documentId}
                className="navbar-submenu-modelo"
                onClick={() => handleModelClick(modelo.slug)}
              >
                {modelo.nombre}
              </div>
            ))}
            {activeSerie?.modelos?.length > 8 && (
              <div className="navbar-submenu-ver-mas" onClick={handleSeeMoreClick}>
                Ver más
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Submenu;
