import React, { useState, useEffect } from "react";
import LandingSwiper from "../Landing/LandingSwiper";
import RecommendedShowcase from "../Landing/RecommendedShowcase";

function LandingDataWrapper() {
  const [lineas, setLineas] = useState([]);

  const fetchLineas = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/lineas?populate[carrusel]=true&populate[series][populate]=imagen_landing&populate[destacados][populate]=imagen_card,serie`
      );
      const data = await response.json();
      if (data && data.data) {
        const fetchedLineas = data.data.map(item => {
          const imagenCarrusel = item.carrusel?.url || "";
          const series = (item.series || []).map(serie => {
            const imagenLanding = serie.imagen_landing?.[0]?.url || "";
            return {
              id: serie.id,
              nombre: serie.nombre || "Sin nombre",
              image: imagenLanding,
              hidden: serie.hidden || false,
              posicion: serie.posicion || 0
            };
          });
          return {
            id: item.id,
            nombre: item.nombre || "Sin nombre",
            image: imagenCarrusel,
            destacados: item.destacados || [],
            series: series.sort((a, b) => a.posicion - b.posicion),
            documentId: item.documentId,
          };
        });
        setLineas(fetchedLineas);
      } else {
        console.error("La data de lineas no tiene la estructura esperada:", data);
      }
    } catch (error) {
      console.error("Error al obtener lineas:", error);
    }
  };

  useEffect(() => {
    fetchLineas();
  }, []);

  // Slides para LandingSwiper: usamos la imagen del carrusel
  const slides = lineas
  .filter(item => item.image)
  .map(item => ({
    image: item.image,
    nombre: item.nombre,
    documentId: item.documentId  // Make sure your API returns this property or adjust accordingly.
  }));

  // Recommended cards: obtenemos el primer destacado (si existe) y usamos su imagen_card,
  // además del nombre del destacado y otras propiedades necesarias.
  const recommendedCards = lineas
    .filter(
      item =>
        item.destacados &&
        item.destacados.length > 0 &&
        item.destacados[0].imagen_card &&
        item.destacados[0].serie &&
        item.destacados[0].serie.nombre
    )
    .map(item => ({
      image: item.destacados[0].imagen_card.url,
      nombre: item.destacados[0].nombre,
      slug: item.destacados[0].slug,
      serie: item.destacados[0].serie.nombre
    }));

  return (
    <div>
   {/*} Desactivado el showcase por motivos de prueba.
      
      {slides.length > 0 ? (
        <LandingSwiper slides={slides} />
      ) : (
        <p>Loading carousel...</p>
      )} {*/}
      {recommendedCards.length > 0 ? (
        <RecommendedShowcase cardsData={recommendedCards} cardsToShow={3} />
      ) : (
        <p>Loading recommended cards...</p>
      )}
    </div>
  );
}

export default LandingDataWrapper;
