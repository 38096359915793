import React from 'react';
import CustomShowcase from '../General/CustomShowcase';
import './RecommendedShowcase.scss';

function RecommendedShowcase({ cardsData, cardsToShow = 3 }) {
  return (
    <div className="recommended-showcase-container">
      <h2 className="landing-peru-showcase-title">Productos destacados</h2>
      <CustomShowcase cardsData={cardsData} cardsToShow={cardsToShow} />
    </div>
  );
}

export default RecommendedShowcase;
