import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Home.css';
import PageFooter from '../components/PageFooter';
import HomeBannerSection from '../components/Home/HomeBannerSection/HomeBannerSection';
import HomeShowcaseSection from '../components/Home/HomeShowcaseSection/HomeShowcaseSection';
import HomeNoticiasSection from '../components/Home/HomeNoticiasSection/HomeNoticiasSection';
import HomeContactoSection from '../components/Home/HomeContactoSection/HomeContactoSection';

const Home = () => {
  // Referencias para las secciones
  const section1Ref = useRef(null);
  const contactSectionRef = useRef(null);

  // Obtener la ubicación actual
  const location = useLocation();

  // Actualizar título y meta descripción al montar el componente
  useEffect(() => {
    document.title = 'Zoomlion Latinoamérica | Home';
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      'Maquinaria de alta calidad de Zoomlion Latinoamérica. Soluciones innovadoras para la construcción, minería y agricultura.';
  }, []);

  // Al cambiar la ruta a '/' se hace scroll hacia la parte superior
  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location.pathname]);

  // Función para hacer scroll a la sección de contacto
  const scrollToContact = () => {
    if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="section section1" ref={section1Ref}>
        <HomeBannerSection scrollToContact={scrollToContact} />
      </div>
      <div className="section section2">
        <HomeShowcaseSection />
      </div>
      <div className="section section3" ref={contactSectionRef}>
        <HomeContactoSection />
      </div>
      <div className="section section4">
        <HomeNoticiasSection />
      </div>
      <div className="section fp-auto-height">
        <PageFooter />
      </div>
    </>
  );
};

export default Home;
