import React from 'react';
import HomeLineaSliderMobile from './HomeLineaSliderMobile';
import HomeSerieSliderMobile from './HomeSerieSliderMobile';
import './HomeShowcaseMobile.css';

function HomeShowcaseMobile({ 
  lineas, 
  currentLinea, 
  currentLineaSeries, 
  handleLineaChange, 
  lineaSliderRef, 
  serieSliderRef, 
  loading 
}) {
  // Buscamos la línea seleccionada usando documentId
  const selectedLinea = lineas.find(linea => linea.documentId === currentLinea);
  
  return (
    <div className="home-showcase-mobile-container">
      <div className="home-showcase-mobile-lineas-wrapper">
        <HomeLineaSliderMobile 
          lineas={lineas} 
          onLineaChange={handleLineaChange} 
          sliderRef={lineaSliderRef} 
          loading={loading} 
        />
      </div>
      <div className="home-showcase-mobile-series-wrapper">
        <HomeSerieSliderMobile 
          series={currentLineaSeries} 
          sliderRef={serieSliderRef} 
          loading={loading} 
          linea={selectedLinea}  // Se pasa el objeto seleccionado con documentId
        />
      </div>
      {loading && <div>Loading...</div>}
    </div>
  );
}

export default HomeShowcaseMobile;
