// /src/components/Linea/SeriesCards.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./SeriesCards.css";

function SeriesCards({ serie, isSelected, linea }) {
  const navigate = useNavigate();

  const handleClick = () => {
    const effectiveLineaId = linea && linea.documentId ? linea.documentId : undefined;
    if (!effectiveLineaId) {
      console.error("No se encontró documentId para la línea:", linea);
      return;
    }
    const stateToPass = {
      selectedLineaId: effectiveLineaId,
      selectedSerie: serie,
    };
    navigate('/productos', { state: stateToPass });
  };

  return (
    <div
      className={`series-cards-card ${isSelected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <img
        src={serie.image}
        alt={serie.nombre}
        className="series-cards-image"
      />
      <p className="series-cards-title">{serie.nombre}</p>
    </div>
  );
}

export default SeriesCards;
