import React from 'react';
import './CustomWhatsapp.css';

function CustomWhatsapp({ onClick }) {
  return (
    <button className="custom-whatsapp-button" onClick={onClick}>
      {/* Puedes reemplazar el src por el icono oficial de WhatsApp */}
      <img 
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/512px-WhatsApp.svg.png?20220228223904"
        alt="WhatsApp" 
        className="custom-whatsapp-icon" 
      />
    </button>
  );
}

export default CustomWhatsapp;
