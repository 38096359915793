import React from 'react';
import { Link } from 'react-router-dom';
import './CustomCard.css';

function CustomCard({ data }) {
  return (
    <div className="custom-card">
    <h3 className="custom-card-title">{data.nombre}</h3>
    <h4 className="custom-card-serie">{data.serie}</h4>
      <img
        src={data.image}
        alt={data.nombre}
        className="custom-card-image"
      />
      <Link to={`/modelo/${data.slug}`} className="custom-card-button">
        VER MÁS
      </Link>
    </div>
  );
}

export default CustomCard;
