// src/components/HomeNoticiasSectionMobile.js
import React, { useState, useEffect } from 'react';
import HomeNoticiasSectionMobileCard from './HomeNoticiasSectionMobileCard';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // Importamos el componente Slider de react-slick
import 'slick-carousel/slick/slick.css'; // Importamos los estilos de Slick
import 'slick-carousel/slick/slick-theme.css';
import './HomeNoticiasSectionMobile.css';

const HomeNoticiasSectionMobile = () => {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/noticias?populate=previsualizacion`);
        const data = await response.json();
       /* console.log('API Response:', data); // Verifica la estructura de la respuesta*/

        const noticiasArray = data.data.map((noticia) => ({
          id: noticia.id,
          titulo: noticia.titulo || 'Sin título',
          contenido: noticia.contenido || 'Sin contenido',
          previsualizacion: noticia.previsualizacion?.[0]?.url || '', // Acceso directo a previsualización
          fechaPublicacion: noticia.publishedAt || 'Sin fecha',
          slug: noticia.slug || '', // Acceso directo al slug
        }));

        // Ordenar por fecha de publicación en orden descendente y tomar las 3 más recientes
        const sortedNoticias = noticiasArray.sort(
          (a, b) => new Date(b.fechaPublicacion) - new Date(a.fechaPublicacion)
        );

        setNoticias(sortedNoticias.slice(0, 3));
      } catch (error) {
        console.error('Error fetching noticias:', error);
      }
    };

    fetchNoticias();
  }, []);

  // Configuración de React Slick para el slider
  const settings = {
    dots: true,
    infinite: true,
    arrows: false, // Sin flechas
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="home-noticias-section-mobile">
      {/* Nueva sección contenedora */}
      <div className="home-noticias-section-mobile-container">
        <h2 className="home-noticias-section-mobile-title">Noticias</h2>
        <div className="home-noticias-section-mobile-slider-wrapper">
          <Slider {...settings}>
            {noticias.map((noticia) => (
              <div key={noticia.id}>
                <HomeNoticiasSectionMobileCard noticia={noticia} />
              </div>
            ))}
          </Slider>
        </div>
        {/* Botón Ver Más */}
        <div className="home-noticias-section-mobile-ver-mas-wrapper">
          <Link to="/noticias" className="home-noticias-section-mobile-ver-mas">
            VER MÁS
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeNoticiasSectionMobile;
