import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './LineaBanner.scss';

const LineaBanner = () => {
  const { slug } = useParams();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const imagePath = `/images/pages/lineas/banner-${slug}.webp`;

  const lineTitles = {
    agricultura: "AGRICULTURA",
    mineria: "MINERÍA",
    elevacion: "ELEVACIÓN",
    "movimiento-de-tierras": "MOVIMIENTO DE TIERRAS",
    "gruas-moviles": "GRÚAS MÓVILES",
    "gruas-torre": "GRÚAS TORRE",
    "montacargas": "MONTACARGAS",
    "concreto": "CONCRETO",
  };

  const pageTitle = lineTitles[slug] || "LÍNEA";

  useEffect(() => {
    const img = new Image();
    img.src = imagePath;
    img.onload = () => {

      setImageLoaded(true);
    };
  }, [imagePath]);

  if (!imageLoaded) return null;

  return (
    <div
      className="linea-banner"
      style={{
        backgroundImage: `url(${imagePath})`,
      }}
    >
      <h2 className="linea-banner-title">{pageTitle}</h2>
      <div className="linea-banner-breadcrumb">
        <Link to="/" className="linea-banner-breadcrumb-link">Inicio</Link>
        <span className="linea-banner-breadcrumb-divider">/</span>
        <Link to="/productos" className="linea-banner-breadcrumb-link">Líneas</Link>
        <span className="linea-banner-breadcrumb-divider">/</span>
        <span className="linea-banner-breadcrumb-current">{pageTitle}</span>
      </div>
    </div>
  );
};

export default LineaBanner;
