import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomWhatsapp from '../General/CustomWhatsapp';

function WhatsappComponent() {
  const location = useLocation();
  const isColombia = location.pathname.includes('colombia');
  const currentCountry = isColombia ? 'Colombia' : 'Perú';

  // Configuración por país
  const countryConfig = {
    Perú: {
      phoneNumber: '5112131330',
      message: "¡Hola! Vengo desde la web oficial de Zoomlion Perú. Quiero hacerte una consulta."
    },
    Colombia: {
      phoneNumber: '573018115298',
      message: "¡Hola! Vengo desde la web oficial de Zoomlion Colombia. Quiero hacerte una consulta."
    }
  };

  const handleClick = () => {
    // Google Tag Manager event
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'whatsapp_click',
        event_category: 'engagement',
        event_label: currentCountry,
        value: 1,
      });
    }

    const config = countryConfig[currentCountry];
    
    // Solución garantizada para el mensaje en WhatsApp
    const formattedNumber = config.phoneNumber.replace(/\D/g, ''); // Elimina todo excepto dígitos
    const encodedMessage = encodeURIComponent(config.message).replace(/%20/g, '+');
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedNumber}&text=${encodedMessage}`;
    
    // Alternativa adicional que funciona en todos los dispositivos
    const fallbackUrl = `https://wa.me/${formattedNumber}?text=${encodedMessage}`;
    
    // Intentamos con el primer método, si falla usamos el alternativo
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer') || 
    window.open(fallbackUrl, '_blank', 'noopener,noreferrer');
  };

  return <CustomWhatsapp onClick={handleClick} />;
}

export default WhatsappComponent;