// /src/components/Linea/LineaWrapper.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import LineaHero from "./LineaHero";
import SeriesShowcase from "./SeriesShowcase";
import SeriesButton from "./SeriesButton";

function LineaWrapper() {
  const { slug } = useParams();
  const [linea, setLinea] = useState(null);
  const [selectedSerie, setSelectedSerie] = useState(null);

  const fetchLinea = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/lineas?filters[slug][$eq]=${slug}&populate[carrusel]=true&populate[series][populate]=imagen_landing&populate[destacados][populate]=imagen_card,serie`
      );
      const data = await response.json();
      if (data && data.data && data.data.length > 0) {
        const item = data.data[0];
        const imagenCarrusel = item.carrusel?.url || "";
        const series = (item.series || []).map(serie => {
          const imagenLanding = serie.imagen_landing?.[0]?.url || "";
          return {
            documentId: serie.documentId,
            nombre: serie.nombre || "Sin nombre",
            image: imagenLanding,
            hidden: serie.hidden || false,
            posicion: serie.posicion || 0
          };
        });
        const processedLinea = {
          documentId: item.documentId,
          nombre: item.nombre || "Sin nombre",
          descripcion: item.descripcion,
          image: imagenCarrusel,
          destacados: item.destacados || [],
          series: series.sort((a, b) => a.posicion - b.posicion)
        };
        setLinea(processedLinea);
        // Por defecto, seleccionamos la primera serie (si existe)
        if (processedLinea.series && processedLinea.series.length > 0) {
          setSelectedSerie(processedLinea.series[0]);
        }
      } else {
        console.error("No se encontró la línea con el slug:", slug);
      }
    } catch (error) {
      console.error("Error al obtener la línea:", error);
    }
  };

  useEffect(() => {
    fetchLinea();
  }, [slug]);

  if (!linea) {
    return <p>Cargando datos...</p>;
  }

  return (
    <div className="linea-page-container">
      <LineaHero 
        imagenLanding={linea.image} 
        serieNombre={linea.nombre} 
        linea={linea}
      />
      <SeriesShowcase 
        series={linea.series} 
        selectedSerie={selectedSerie}
        setSelectedSerie={setSelectedSerie}
        linea={linea}
      />
    </div>
  );
}

export default LineaWrapper;
