import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeLineaSliderMobile.css';
import nextArrow from '../../../../images/lineaslider/next-mobile.png';
import prevArrow from '../../../../images/lineaslider/prev-mobile.png';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-linea-slider-mobile-arrow home-linea-slider-mobile-next`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next" className="home-linea-slider-mobile-arrow-image" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-linea-slider-mobile-arrow home-linea-slider-mobile-prev`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Previous" className="home-linea-slider-mobile-arrow-image" />
    </div>
  );
}

const HomeLineaSliderMobile = ({ lineas, onLineaChange, sliderRef, loading }) => {
  const [selectedLinea, setSelectedLinea] = useState(0);

  // Maneja el cambio del slide y actualiza la línea seleccionada
  const handleSlideChange = (index) => {
    setSelectedLinea(index);
    onLineaChange(index);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => handleSlideChange(next),
  };

  return (
    <div className="home-linea-slider-mobile-wrapper">
      <div className="home-linea-slider-mobile">
        {!loading && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {lineas.map((linea, index) => (
              <div key={linea.documentId}>
                <div className={`home-linea-slider-mobile-item ${selectedLinea === index ? 'selected' : ''}`}>
                  <div className="home-linea-slider-mobile-image-wrapper">
                    <img
                      src={linea.image}
                      alt={linea.nombre}
                      className="home-linea-slider-mobile-image"
                    />
                  </div>
                  <h3 className="home-linea-slider-mobile-label">{linea.nombre}</h3>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default HomeLineaSliderMobile;
