import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Campaign() {
  const { slug } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/campaigns?filters[slug][$eq]=${slug}`
        );
        const { data } = await response.json();

        if (data && data.length > 0) {
          setCampaign(data[0]);
        } else {
          setError('No se encontró la campaña solicitada.');
        }
      } catch (e) {
        setError('Error al conectar con el servidor.');
      }
    };

    if (slug) {
      fetchCampaign();
    }
  }, [slug]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!campaign) {
    return <div>Cargando...</div>;
  }

  const {
    documentId,
    titulo,
    slug: campaignSlug,
    bajada,
    createdAt,
    updatedAt,
    publishedAt,
    oferta,
  } = campaign;

  return (
    <div>
      <h1>{titulo}</h1>
      <p>{bajada}</p>
      <p><strong>Slug:</strong> {campaignSlug}</p>
      <p><strong>Document ID:</strong> {documentId}</p>
      <p><small>Created: {createdAt}</small></p>
      <h2>Ofertas:</h2>
      <ul>
        {oferta.map((o) => (
          <li key={o.id}>
            <p><strong>{o.titulo || 'Oferta sin título'}</strong></p>
            <p>{o.descripcion || 'Sin descripción disponible'}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Campaign;