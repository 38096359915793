import React from "react";
import './LineaHero.scss';
import LineaButton from "./LineaButton";
import LineaContact from "./LineaContact";

const LineaHero = ({ imagenLanding, serieNombre, linea }) => {
  return (
    <div className="linea-hero-container">
      {/* Imagen: en mobile se muestra arriba, en desktop a la izquierda */}
      <div className="linea-hero-left">
        <img
          src={imagenLanding}
          alt={serieNombre}
          className="linea-hero-image"
        />
      </div>
      {/* Contenido: título, descripción y botones */}
      <div className="linea-hero-right">
        <h1 className="linea-hero-title">{serieNombre}</h1>
        <p className="linea-hero-description">{linea.descripcion}</p>
        <div className="linea-hero-buttons">
          <LineaContact />
          <LineaButton linea={linea} />
        </div>
      </div>
    </div>
  );
};

export default LineaHero;
