import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import './PageBanner.css';

const PageBanner = () => {
  const location = useLocation();
  const bannerRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { slug } = useParams(); // Para obtener el slug si es necesario

  // Eliminar el '/' inicial y reemplazar '/' por '-' para el nombre del archivo de imagen
  let routeName = location.pathname.substring(1).replace('/', '-');
  
  // Si la ruta es /noticias/slug, asigna 'noticias' como routeName
  if (location.pathname.startsWith('/noticias/')) {
    routeName = 'noticias';
  }

  const imagePath = `/images/pages/banner-${routeName}.webp`;

  const routeTitles = {
    productos: "PRODUCTOS",
    ofertalaboral: "OFERTA LABORAL",
    contacto: "CONTACTO",
    noticias: "NOTICIAS",
    servicios: "SERVICIOS",
    nosotros: "NOSOTROS",
    concurso: "CONCURSO",
    noticia: "NOTICIAS",
    agricultura: "AGRICULTURA",
  };

  const pageTitle = routeTitles[routeName] || "Page";

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setImageLoaded(true); // Cargar la imagen cuando el banner sea visible
        }
      },
      { threshold: 0.1 } // Activar cuando el 10% del banner sea visible
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  // Evitar mostrar el banner en la página principal o en una ruta dinámica específica
  if (location.pathname === '/' || (location.pathname.startsWith('/products/') && location.pathname !== '/products')) {
    return null;
  }

  return (
    <div
      className="page-banner"
      ref={bannerRef}
      style={{
        backgroundImage: imageLoaded ? `url(${imagePath})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <h2 className="page-banner-title">{pageTitle}</h2>
      <div className="page-banner-breadcrumb">
        <Link to="/" className="page-banner-breadcrumb-link">Inicio</Link>
        <span className="page-banner-breadcrumb-divider">/</span>
        <span className="page-banner-breadcrumb-current">{pageTitle}</span>
      </div>
    </div>
  );
};

export default PageBanner;
