import React, { useState, useEffect } from 'react';
import HomeShowcaseDesktop from './HomeShowcaseDesktop/HomeShowcaseDesktop';
import HomeShowcaseMobile from './HomeShowcaseMobile/HomeShowcaseMobile';
import DebugDataAlert from '../../Debug/DebugDataAlert';
import useHomeShowcaseData from './useHomeShowcaseData';
import './HomeShowcaseSection.css';

function HomeShowcaseSection() {
  // Usamos el custom hook para obtener todos los estados y callbacks necesarios
  const {
    lineas,
    currentLinea,
    currentLineaSeries,
    loading,
    handleLineaChange,
    lineaSliderRef,
    serieSliderRef,
  } = useHomeShowcaseData();

  // Detectamos si es mobile (parte estética que puede seguir en este componente)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="home-showcase-section-container">
      <DebugDataAlert 
        data={{ lineas, currentLinea, currentLineaSeries, isMobile, loading }}
      />
      {isMobile ? (
        <HomeShowcaseMobile
          lineas={lineas}
          currentLinea={currentLinea}
          currentLineaSeries={currentLineaSeries}
          handleLineaChange={handleLineaChange}
          lineaSliderRef={lineaSliderRef}
          serieSliderRef={serieSliderRef}
          loading={loading}
        />
      ) : (
        <HomeShowcaseDesktop
          lineas={lineas}
          currentLinea={currentLinea}
          currentLineaSeries={currentLineaSeries}
          handleLineaChange={handleLineaChange}
          lineaSliderRef={lineaSliderRef}
          serieSliderRef={serieSliderRef}
          loading={loading}
        />
      )}
      {loading && <div>Loading...</div>}
    </div>
  );
}

export default HomeShowcaseSection;
