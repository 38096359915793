import React, { useEffect, useRef, useState } from "react";
import styles from "./LandingFeature.module.scss";
import content from "./featureContent.json";

const LandingFeature = ({ type, index }) => {
  // Llamamos a los hooks de forma incondicional
  const imageRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  const feature = content[type];

  useEffect(() => {
    if (!feature) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Solo se activa una vez
        }
      },
      { threshold: 0.3 }
    );
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }
    return () => observer.disconnect();
  }, [feature]);

  if (!feature) return null;

  const imageSrc = require(`./${feature.image}`);
  const containerClass = `${styles["landing-feature-container"]} ${
    index % 2 !== 0 ? styles["reverse"] : ""
  }`;

  return (
    <section className={styles["landing-feature-section"]}>
      <div className={containerClass}>
        <div className={styles["landing-feature-image"] + " fade-up " + (isInView ? "in-view" : "")} ref={imageRef}>
          <img src={imageSrc} alt={feature.title} />
        </div>
        <div className={styles["landing-feature-text"]}>
          <h2 className={styles["landing-feature-title"]}>{feature.title}</h2>
          <p className={styles["landing-feature-description"]}>{feature.text}</p>
        </div>
      </div>
    </section>
  );
};

export default LandingFeature;
