import React from 'react';
import { Link } from 'react-router-dom';
import ModelCard from './ModelCard';
import './ProductGrid.css';

function ProductGrid({ modelos, loading, error, selectedSerieName, selectedLineaName, selectedLineaSlug }) {
  return (
    <div className="product-grid-container">
      <p className="product-grid-description">
        Descubre la gama completa de productos Zoomlion disponibles en Latinoamérica. Encuentra aquí el equipo ideal para tus proyectos de construcción, minería o infraestructura.
      </p>

      {selectedSerieName && (
        <h2 className="product-grid-breadcrumb">
          <span style={{ fontWeight: 300 }}>Modelos de la línea </span>
          <span style={{ fontWeight: 600 }}>
            <Link to={`/lineas/${selectedLineaSlug}`} className="product-grid-linea-link">
              {selectedLineaName}
            </Link>
          </span>
          <span style={{ fontWeight: 300 }}> y la serie </span>
          <span style={{ fontWeight: 600 }}>{selectedSerieName}</span>
          <span style={{ fontWeight: 300 }}>:</span>
        </h2>
      )}
      {selectedLineaName && !selectedSerieName && (
        <h2 className="product-grid-breadcrumb">
          <span style={{ fontWeight: 300 }}>Modelos de la línea </span>
          <span style={{ fontWeight: 600 }}>
            <Link to={`/lineas/${selectedLineaSlug}`} className="product-grid-linea-link">
              {selectedLineaName}
            </Link>
          </span>
          <span style={{ fontWeight: 300 }}>:</span>
        </h2>
      )}

      <div className="product-grid">
        {!selectedSerieName && !selectedLineaName ? (
          <div className="product-grid-message">Selecciona una línea o serie</div>
        ) : loading ? (
          <div className="product-grid-message">Cargando modelos...</div>
        ) : error ? (
          <div className="product-grid-message">Error: {error}</div>
        ) : modelos.length === 0 ? (
          <div className="product-grid-message">No hay modelos disponibles</div>
        ) : (
          modelos.map((modelo) => (
            <ModelCard
              key={modelo.documentId}
              nombre={modelo.nombre}
              especificaciones={modelo.especificaciones}
              imagen={modelo.imagen_card || '/images/default-banner.webp'}
              slug={modelo.slug}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ProductGrid;
