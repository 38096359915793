import React, { useRef, useEffect, useState } from 'react';
import p5 from 'p5';

const HeroCanvas = () => {
  const canvasRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Detectar cambio de tamaño de pantalla
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) return; // No iniciar p5 en mobile

    const sketch = (p) => {
      let rectangles = [];
      let particles = [];
      let frameCounter = 0;
      let mouseX = p.windowWidth / 2;
      let mouseY = p.windowHeight / 2;
      const maxRectangles = 10;
      const greenColor = [170, 206, 57];

      class RotatingRectangle {
        constructor() {
          this.x = p.random(p.width);
          this.y = p.random(p.height);
          this.size = p.random(30, 100);
          this.angle = p.random(360);
          this.alpha = 0;
          this.lifespan = 10000;
          this.createdAt = p.millis();
          this.rotationSpeed = p.random(-0.5, 0.5);
          this.isExploding = false;
          this.hasExploded = false; // Evita explosión cuando desaparece naturalmente
        }

        update() {
          const elapsed = p.millis() - this.createdAt;
          this.alpha = elapsed < 2000 ? p.map(elapsed, 0, 2000, 0, 76) : p.map(elapsed, 2000, this.lifespan, 76, 0, true);
          
          // Si desaparece naturalmente, no explota
          if (elapsed >= this.lifespan) {
            return false;
          }

          return !this.hasExploded;
        }

        explode() {
          if (this.hasExploded) return; // Evita múltiples explosiones

          // Generar partículas de color verde
          for (let i = 0; i < 10; i++) {
            particles.push(new Particle(this.x, this.y, this.size / 5));
          }
          this.hasExploded = true;
        }

        display() {
          p.push();
          p.translate(this.x, this.y);
          p.rotate(p.radians(this.angle));
          p.fill(greenColor[0], greenColor[1], greenColor[2], this.alpha);
          p.noStroke();
          p.rectMode(p.CENTER);
          p.rect(0, 0, this.size, this.size);
          p.pop();
        }
      }

      class Particle {
        constructor(x, y, size) {
          this.x = x;
          this.y = y;
          this.size = size;
          this.alpha = 255;
          this.velX = p.random(-2, 2);
          this.velY = p.random(-2, 2);
          this.angle = p.random(360);
          this.lifespan = 1000;
          this.createdAt = p.millis();
        }

        update() {
          this.x += this.velX;
          this.y += this.velY;
          const elapsed = p.millis() - this.createdAt;
          this.alpha = p.map(elapsed, 0, this.lifespan, 255, 0);
          return elapsed < this.lifespan;
        }

        display() {
          p.push();
          p.translate(this.x, this.y);
          p.rotate(p.radians(this.angle));
          p.fill(greenColor[0], greenColor[1], greenColor[2], this.alpha);
          p.noStroke();
          p.triangle(-this.size / 2, this.size / 2, this.size / 2, this.size / 2, 0, -this.size / 2);
          p.pop();
        }
      }

      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight).parent(canvasRef.current);
        p.frameRate(30);
      };

      p.draw = () => {
        p.clear();
        if (frameCounter % 60 === 0 && rectangles.length < maxRectangles) {
          rectangles.push(new RotatingRectangle());
        }
        frameCounter++;
        rectangles = rectangles.filter((rect) => rect.update());
        rectangles.forEach((rect) => rect.display());
        particles = particles.filter((particle) => particle.update());
        particles.forEach((particle) => particle.display());
      };

      // Si el mouse toca un rectángulo, lo hace explotar en partículas
      p.mouseMoved = () => {
        mouseX = p.mouseX;
        mouseY = p.mouseY;
        rectangles.forEach((rect) => {
          if (p.dist(rect.x, rect.y, mouseX, mouseY) < rect.size / 2) {
            rect.explode();
          }
        });
      };

      p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      };
    };

    const myP5 = new p5(sketch);

    return () => {
      myP5.remove();
    };
  }, [isMobile]);

  if (isMobile) return null; // Ocultar canvas en móviles

  return <div ref={canvasRef} className="canvas-background" style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }} />;
};

export default HeroCanvas;
